<template>
  <base-layout>
    <add-edit-slow-zone-area />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10">
          <div class="">
            <title-plus
              :title="$t('components.slowSpeedAreaManagement.headline')"
              @plus="add"
            />
          </div>
        </div>

        <div class="flex flex-col mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="
              $t(
                'components.slowSpeedAreaManagement.summary.totalSlowSpeedAreas'
              )
            "
            :value="indexMetaData.summary.total"
            variant="gray"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('Slow Speed Areas')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`SlowSpeedAreasIndex`"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        :isMultipleViewEnabled="true"
        @meta="(e) => (indexMetaData = e)"
        @view="(v) => (layoutView = v)"
      >
        <template #default="{ data }">
          <template v-if="layoutView === 'list'">
            <FSTableRow
              v-for="(item, itemIndex) in data"
              :key="itemIndex"
              text-fallback-always
            >
              <FSTableRowItem>
                <router-link
                  class="font-bold text-blue-600 capitalize"
                  :to="{
                    name: 'ViewSlowZoneArea',
                    params: { id: item.id },
                  }"
                >
                  #{{ item.id | onlyLastFive }}
                </router-link>
              </FSTableRowItem>

              <FSTableRowItem>
                <router-link
                  class="font-semibold text-blue-700 capitalize"
                  :to="{
                    name: 'ViewSlowZoneArea',
                    params: { id: item.id },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </FSTableRowItem>

              <FSTableRowItem
                :text="item.geofence ? item.geofence.name : '--'"
              />

              <FSTableRowItem :text="item.address ? item.address : '--'" />

              <FSTableRowItem>
                <x-status
                  :variant="item.is_active ? 'green' : 'gray'"
                  :text="item.is_active ? 'Active' : 'Inactive'"
                  profile="fleet"
                />
              </FSTableRowItem>

              <FSTableRowItem>
                <div class="flex items-center">
                  <oto-edit-icon @click="edit(item)" />
                  <MoreActionsDropdown
                    :key="`more-actions-${itemIndex}`"
                    :type="`slowSpeedArea`"
                    :data="item"
                    @exportKML="handleExportKML(item)"
                    @change="handleAction('change', itemIndex, $event)"
                    @delete="handleAction('delete', itemIndex, $event)"
                  />
                </div>
              </FSTableRowItem>
            </FSTableRow>
          </template>
          <template v-if="layoutView === 'grid'">
            <FSTableRow
              v-for="(item, itemIndex) in data"
              :key="itemIndex"
              text-fallback-always
            >
              <FSTableGridItem>
                <div
                  class="bg-gray-50 rounded-lg flex justify-between items-center p-1"
                >
                  <div>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewSlowZoneArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                  <div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" />
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :type="`slowSpeedArea`"
                      :data="item"
                      @exportKML="handleExportKML(item)"
                      @change="handleAction('change', itemIndex, $event)"
                      @delete="handleAction('delete', itemIndex, $event)"
                    />
                  </div>
                </div>
                <div class="h-52">
                  <MapView :data="item" />
                </div>
              </FSTableGridItem>
            </FSTableRow>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'

import XStatus from '@/components/badge/XStatus'

import { EventBus } from '@/utils/EventBus'

import { SlowZoneAreaConfig } from '@/config/SlowZoneAreaConfig'
import AddEditSlowZoneArea from './AddEditSlowZoneArea'

// import { EventBus } from "@/utils/EventBus";
import SummaryCard from '@/components/cards/SummaryCard'
import MapView from '@/components/service-area/MapView'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableGridItem,
} from '@/components/fs-table'
import {
  parseToOverlay,
  createKML,
  downloadKMLFile,
} from '@/composites/geofence/shared/geofence'
export default {
  name: 'SlowZoneAreas',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    // DateRangePicker,
    SummaryCard,
    XStatus,
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),
    AddEditSlowZoneArea,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableGridItem,
    MapView,
  },
  data() {
    return {
      layoutView: 'list',
      indexMetaData: {
        summary: {
          total: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: SlowZoneAreaConfig.api.index,
      tableHeaders: [
        {
          text: `${this.$t(
            'components.slowSpeedAreaManagement.table.columns.id'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.slowSpeedAreaManagement.table.columns.name'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.slowSpeedAreaManagement.table.columns.serviceAreaName'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.slowSpeedAreaManagement.table.columns.address'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.slowSpeedAreaManagement.table.columns.status'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.slowSpeedAreaManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(SlowZoneAreaConfig.events.refresh, () => {
      this.$store.dispatch('fsTable/fetchData')
    })
  },
  methods: {
    parseToOverlay,
    createKML,
    downloadKMLFile,
    add: function() {
      EventBus.$emit(SlowZoneAreaConfig.events.editingData, {
        // enable status-switch by default
        should_trigger: true,
      })
      dispatchEvent(new Event(SlowZoneAreaConfig.events.sorToggle))
    },
    edit: async function(item) {
      console.log(item)
      var slowzoneAreaDetails = await this.$http
        .get(SlowZoneAreaConfig.api.details(item.id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
      if (slowzoneAreaDetails == undefined) return

      delete slowzoneAreaDetails.created_by
      // delete slowzoneAreaDetails.id
      // slowzoneAreaDetails.geofence = slowzoneAreaDetails.geofence.id
      console.log('MOrphed', { ...slowzoneAreaDetails })
      EventBus.$emit(SlowZoneAreaConfig.events.editingData, slowzoneAreaDetails)
      dispatchEvent(new Event(SlowZoneAreaConfig.events.sorToggle))
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return SlowZoneAreaConfig.api.update(id)
    },
    handleAction(type, index, data) {
      this.$store.dispatch('fsTable/fetchData')
      console.log('service-area', type, index, data)
      if (type === 'change') {
        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Status`,
          text: `Slowzone Area Status has been changed`,
        })

        return
      }

      if (type === 'delete') {
        const name = this.indexData[index].name || '--'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Parking area deleted`,
          text: `${name} parking area has been deleted`,
        })

        return
      }
    },
    handleExportKML(data) {
      const coordinates = this.parseToOverlay(data.coords)

      const kmlString = this.createKML(coordinates)

      this.downloadKMLFile(data.name, kmlString)
    },
  },
}
</script>

<style></style>
