<template>
  <div>
    <loading :active.sync="isLoading" />
    <AddEditRentStationArea />
    <div class="flex flex-wrap" v-if="rent_station_area != null">
      <div :class="is_full ? 'w-0' : 'w-1/2 px-6 py-4 bg-white md-max:w-full'">
        <div class="flex flex-wrap items-center">
          <div class="flex items-center w-2/3 pl-2 md-max:w-full">
            <div
              class="items-center justify-center mr-4 cursor-pointer back-button"
              @click="goBack"
            >
              <i class="fas fa-arrow-left"></i>
            </div>
            <span class="title">{{ titleText }}</span>
            <h3 class="pl-1 font-semibold text-gray-900 text-22px">
              {{
                $t(
                  'components.rentStationAreaManagement.details.headline.details'
                )
              }}
              (#{{ rent_station_area.id | onlyLastFive }})
            </h3>
          </div>
          <div
            class="flex items-center justify-end w-1/3 pl-2 md-max:w-full md-max:pt-3"
          >
            <oto-edit-button @click="edit" />
          </div>
        </div>
        <div
          class="flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"
        >
          <SummaryCard
            :title="
              $t(
                'components.rentStationAreaManagement.details.summary.totalAssignedVehicles'
              )
            "
            :value="rent_station_area.total_assigned_vehicles"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.rentStationAreaManagement.details.summary.totalRentals'
              )
            "
            :value="rent_station_area.total_rentals"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.rentStationAreaManagement.details.summary.totalOngoingRentals'
              )
            "
            :value="rent_station_area.total_ongoing_rentals"
            variant="gray"
          />
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">{{
              $t(
                'components.rentStationAreaManagement.details.rentStationAreaName'
              )
            }}</span>
            <span class="block mt-4 detail-value">{{
              rent_station_area.name
            }}</span>
          </div>
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">{{
              $t('components.rentStationAreaManagement.details.serviceAreaName')
            }}</span>
            <span class="block mt-4 detail-value">{{
              rent_station_area.geofence
                ? rent_station_area.geofence.name
                : '--'
            }}</span>
          </div>
        </div>

        <div class="flex items-center my-12">
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">{{
              $t('components.rentStationAreaManagement.details.address')
            }}</span>
            <span class="block mt-4 detail-value">{{
              rent_station_area.address ? rent_station_area.address : ''
            }}</span>
          </div>
          <div class="block w-1/2 pl-2">
            <span class="block detail-title">{{
              $t('components.rentStationAreaManagement.details.rentStationNote')
            }}</span>
            <span class="block mt-4 detail-value">{{
              rent_station_area.description ? rent_station_area.description : ''
            }}</span>
          </div>
        </div>
      </div>

      <div
        :class="
          is_full
            ? 'w-full relative'
            : 'w-1/2 relative md-max:w-full md-max:px-0'
        "
      >
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
          @click="toggleMap"
          @zoom_changed="getCurrentZoom"
        >
          <gmap-marker
            key="current"
            :position="getLatLng(rent_station_area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/Rent_R_icon.png`),
              scaledSize: { width: 55, height: 65 },
            }"
            @mouseover="toggleMarker(rent_station_area, -1)"
            @click="moveCamera(rent_station_area)"
          >
          </gmap-marker>
          <gmap-marker
            :key="index"
            v-for="(area, index) in rent_station_area.other_areas_in_geofence"
            :position="getLatLng(area.center_coords)"
            :icon="{
              url: require(`@/assets/img/areas_pin/Rent_R_icon.png`),
              scaledSize: { width: 55, height: 65 },
            }"
            @mouseover="toggleMarker(area, index)"
            @click="moveCamera(area)"
          >
          </gmap-marker>
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <div v-html="infoContent"></div>
          </gmap-info-window>
        </gmap-map>

        <div>
          <div
            class="flex flex-wrap items-center justify-between px-4 area-body"
          >
            <round-tab
              v-for="(item, index) in bottom_tabs"
              :key="index"
              :type="item.type"
              :name="item.name"
              v-on:changeArea="changeArea"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RentStationAreaConfig } from '@/config/RentStationAreaConfig'

import { gmapApi } from 'vue2-google-maps'
import OtoEditButton from '@/components/ui/OtoEditButton.vue'
import AddEditRentStationArea from '@/views/geofence/rent-station-area/AddEditRentStationArea.vue'
import { EventBus } from '@/utils/EventBus'

import RoundTab from '@/components/service-area/RoundTab.vue'
import SummaryCard from '@/components/cards/SummaryCard'
export default {
  name: 'ViewRenStationArea',
  components: {
    OtoEditButton,
    AddEditRentStationArea,
    RoundTab,
    SummaryCard,
  },
  data() {
    return {
      isLoading: false,
      serviceAreaFleets: [],
      serviceAreaSpeedLimits: [],
      item: {},
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      scrollwheel: true,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      drawingOptions: {
        fillColor: 'rgba(88, 28, 135, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#581C87',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 2,
      },
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      rent_station_area: null,
      is_full: false,
      vehicles: [],
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      alertVehicle: null,
      infoWinOpen: false,
      // infoAlertOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -70,
        },
      },
      type: '',
      polygons: [],
      bottom_tabs: [
        {
          type: 'service',
          name: 'Service Area',
        },
        {
          type: 'rent',
          name: 'Rent Station Area',
        },
      ],
    }
  },

  computed: {
    google: gmapApi,

    id() {
      return this.$route.params.id
    },

    titleText: function() {
      return this.$t(
        'components.rentStationAreaManagement.details.headline.rentStationArea'
      )
    },
  },
  async mounted() {
    this.initFunc()
    window.addEventListener(RentStationAreaConfig.events.refresh, () => {
      this.initFunc()
    })
  },
  methods: {
    async initFunc() {
      this.isLoading = true

      await this.$http
        .get(RentStationAreaConfig.api.details(this.id))
        .then((res) => {
          console.log('res = ', res)
          this.rent_station_area = res.data
          this.isLoading = false
          this.initDraw()
        })
        .catch((err) => {
          console.log('vehicle-header-err', err)
          this.isLoading = false
        })
    },
    initDraw() {
      var self = this
      setTimeout(() => {
        if (!self.$refs.map) return
        console.log('getMIN')
        self.$refs.map.$mapPromise.then((map) => {
          console.log('Hitting promise with', map)
          self.map = map
          if (self.rent_station_area != null) {
            console.log('park = ', self.rent_station_area)
            if (self.rent_station_area.geofence) {
              const overlay = self.parseToOverlay(
                self.rent_station_area.geofence.coords
              )
              self.setServiceOverlayOnMap(overlay)
            }
            console.log(
              'GOT POLYGON COORDS FROM SERVER',
              self.rent_station_area.coords
            )
            const overlay = self.parseToOverlay(self.rent_station_area.coords)
            self.setOverlayOnMap(overlay)
            self.setMapCenterToDrawingCenter(overlay, false)
            this.drawOtherZonesOnMap()
          }
        })
      }, 1000)
    },
    drawOtherZonesOnMap() {
      const otherAreaBounds = []
      const otherParsedPolygons = []

      this.rent_station_area.other_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.drawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.otherAreaBounds = otherAreaBounds
      this.otherParsedPolygons = otherParsedPolygons
    },
    fullMap() {
      this.is_full = !this.is_full
    },
    edit() {
      this.$http
        .get(RentStationAreaConfig.api.details(this.id))
        .then((res) => {
          console.log('rentStationArea = ', res.data)
          delete res.data.created_by
          // delete res.data.id
          // res.data.geofence = res.data.geofence.id
          EventBus.$emit(RentStationAreaConfig.events.editingData, res.data)
          dispatchEvent(new Event(RentStationAreaConfig.events.sorToggle))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getCurrentZoom(z) {
      this.zoom = z
    },

    setServiceOverlayOnMap(payload) {
      this.parsedServicePolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.serviceDrawingOptions,
      })
      this.parsedServicePolygon.setMap(this.map)
      console.log('po = ', this.parsedServicePolygon)
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    setOverlayOnMap(payload) {
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })
      this.parsedPolygon.setMap(this.map)
      console.log(payload)
      console.log('SET ON MAP')
    },
    setMapCenterToDrawingCenter(paths, panToCenter = true) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter)
      if (panToCenter) {
        this.map.panTo(newCenter)
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }

      this.map.fitBounds(bounds)
    },

    goBack() {
      this.$router.push('/geofence/rent-station-areas')
    },
    changeArea(type) {
      this.parsedServicePolygon.setMap(null)
      this.parsedPolygon.setMap(null)
      if (type == this.type) {
        this.type = ''
        this.initDraw()
        return
      } else {
        this.type = type
      }

      if (!this.$refs.map) return

      this.$refs.map.$mapPromise.then((map) => {
        this.map = map
        if (type == 'service') {
          if (this.rent_station_area.geofence) {
            const overlay = this.parseToOverlay(
              this.rent_station_area.geofence.coords
            )
            this.setServiceOverlayOnMap(overlay)
          }
        } else {
          if (this.rent_station_area != null) {
            const overlay = this.parseToOverlay(this.rent_station_area.coords)
            this.setOverlayOnMap(overlay)
            this.setMapCenterToDrawingCenter(overlay, false)
          }
        }
      })
    },

    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = Number(position.split(',')[0])
      let lng = Number(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },

    toggleMap() {
      this.infoWinOpen = false
    },

    moveCamera(area) {
      this.infoWinOpen = false

      if (area.center_coords != null) {
        this.center = this.getLatLng(area.center_coords)
        this.zoom = 15
      }
    },

    toggleMarker(area, idx) {
      this.infoWindowPos = this.getLatLng(area.center_coords)
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
      this.infoContent = this.getInfoWindowContent(area)
    },

    getInfoWindowContent: function(item) {
      //work from here

      return `
        <div class="card">
          <div class="card-content">
            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Rent Station Area Name:</span>
              <span class="ml-2 card-text ">
              <a href="/geofence/rent-station-areas/${
                item.id
              }" class="font-semibold text-blue-600 capitalize " target="_blank"> ${
        item.name == undefined ? ' ' : item.name
      } </a>
              </span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Address:</span>
              <span class="ml-2 card-text">${item.address}</span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Latitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lat
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Longtitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lng
              }</span>
            </div>



            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Active Status:</span>
              <span class="ml-2 card-text font-semibold">${
                item.is_active
                  ? '<span class="text-green-600">Yes<span>'
                  : '<span class="text-gray-600">No<span>'
              }</span>
            </div>




          </div>
        </div>
      `
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.detail-value {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #787881;
}
.title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9393a2;
}

@media (max-width: 990px) {
  .hidden-class {
    display: none !important;
  }
}
</style>
