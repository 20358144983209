<template>
  <section>
    <AddEditSunpodStationArea @refresh="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            :title="
              pageTitle || $t('components.sunpodStationAreaManagement.headline')
            "
            @plus="add"
            :hidePlus="hidePlusButton"
          />
        </div>

        <!-- <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="$t('components.rentStationAreaManagement.summary.total')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />
        </div> -->
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :qso="qso"
          :headers="tableHeaders"
          :endpoint="endpoint"
          :isMultipleViewEnabled="true"
          @meta="(e) => (indexMetaData = e)"
          @view="(v) => (layoutView = v)"
        >
          <template #default="{ data }">
            <template v-if="layoutView === 'list'">
              <template v-for="(item, itemIndex) in data">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewSunpodStationArea',
                        params: { id: item.id },
                      }"
                    >
                      #{{ item.id | onlyLastFive }}
                    </router-link>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: 'ViewSunpodStationArea',
                        params: { id: item.id },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.geofence.name" />
                  <FSTableRowItem :text="item.address" />
                  <FSTableRowItem>
                    <x-status
                      :variant="item.is_active ? 'green' : 'gray'"
                      :text="item.is_active ? 'Active' : 'Inactive'"
                      profile="rent-station"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem class="flex items-center">
                    <OtoEditIcon @click="edit(item)" />
                    <MoreActionsDropdown
                      :key="`more-actions-${itemIndex}`"
                      :type="`sunpodStationArea`"
                      :data="item"
                      @exportKML="handleExportKML(item)"
                      @change="handleAction('change', itemIndex, $event)"
                      @delete="handleAction('delete', itemIndex, $event)"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
            </template>
            <template v-if="layoutView === 'grid'">
              <FSTableRow
                v-for="(item, itemIndex) in data"
                :key="itemIndex"
                text-fallback-always
              >
                <FSTableGridItem>
                  <div
                    class="bg-gray-50 rounded-lg flex justify-between items-center p-1"
                  >
                    <div>
                      <router-link
                        class="font-bold text-blue-600 capitalize"
                        :to="{
                          name: 'ViewRenStationArea',
                          params: { id: item.id },
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </div>
                    <div class="flex items-center">
                      <OtoEditIcon @click="edit(item)" />
                      <MoreActionsDropdown
                        :key="`more-actions-${itemIndex}`"
                        :type="`sunpodStationArea`"
                        :data="item"
                        @exportKML="handleExportKML(item)"
                        @change="handleAction('change', itemIndex, $event)"
                        @delete="handleAction('delete', itemIndex, $event)"
                      />
                    </div>
                  </div>
                  <div class="h-52">
                    <MapView :data="item" />
                  </div>
                </FSTableGridItem>
              </FSTableRow>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </section>
</template>
<script>
import MapView from '@/components/service-area/MapView'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableGridItem,
} from '@/components/fs-table'
import SunpodStationAreaConfig from '@/config/sunpod-station-area.config.js'
import { EventBus } from '@/utils'
import {
  parseToOverlay,
  createKML,
  downloadKMLFile,
} from '@/composites/geofence/shared/geofence'
export default {
  name: 'SunpodStationAreas',
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    XStatus: () => import('@/components/badge/XStatus'),
    // SummaryCard: () => import('@/components/cards/SummaryCard'),
    AddEditSunpodStationArea: () =>
      import(
        '@/views/geofence/sunpod-station-area/AddEditSunpodStationArea.vue'
      ),
    OtoEditIcon: () => import('@/components/ui/OtoEditIcon'),
    MoreActionsDropdown: () =>
      import('@/composites/geofence/shared/MoreActionsDropdown'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableGridItem,
    MapView,
  },
  props: {
    fstId: {
      type: String,
      default: 'sunpod-station-index',
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    pageTitle: {
      type: String,
      default: '',
    },
    endpoint: {
      type: String,
      default: SunpodStationAreaConfig.api.index,
    },
    hidePlusButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layoutView: 'list',
      //   indexMetaData: {
      //     summary: {
      //       total: 0,
      //     },
      //   },

      tableHeaders: [
        {
          text: `${this.$t(
            'components.sunpodStationAreaManagement.table.columns.id'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.sunpodStationAreaManagement.table.columns.name'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.sunpodStationAreaManagement.table.columns.serviceAreaName'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.sunpodStationAreaManagement.table.columns.address'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.sunpodStationAreaManagement.table.columns.status'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.sunpodStationAreaManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  methods: {
    parseToOverlay,
    createKML,
    downloadKMLFile,
    add: function() {
      console.log('sunpod station = ')
      EventBus.$emit(SunpodStationAreaConfig.events.editingData, {})
      dispatchEvent(new Event(SunpodStationAreaConfig.events.sorToggle))
    },
    edit: function(item) {
      console.log(item)
      this.$http
        .get(SunpodStationAreaConfig.api.details(item.id))
        .then((res) => {
          console.log('rent station  = ', res.data)
          EventBus.$emit(SunpodStationAreaConfig.events.editingData, res.data)
          dispatchEvent(new Event(SunpodStationAreaConfig.events.sorToggle))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleAction(type, index, data) {
      if (type === 'change') {
        const activeInactive = data.is_active === true ? 'active' : 'inactive'
        const activatedDeactivated =
          data.is_active === true ? 'activated' : 'deactivated'

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Sunpod area ${activatedDeactivated}`,
          text: `${data.name} sunpod area is now ${activeInactive}`,
        })
        this.$store.dispatch('fsTable/fetchData')

        return
      }

      if (type === 'delete') {
        this.$store.dispatch('fsTable/fetchData')

        this.$notify({
          type: `success`,
          group: `generic`,
          title: `Success`,
          text: `Sunpod Station area has been deleted`,
        })

        return
      }
    },
    handleExportKML(data) {
      const coordinates = this.parseToOverlay(data.coords)

      const kmlString = this.createKML(coordinates)

      this.downloadKMLFile(data.name, kmlString)
    },
  },
}
</script>
<style lang=""></style>
