<template>
  <div
    :class="
      type == 'service'
        ? `flex cursor-pointer items-center bottom-area-text active_${type} res-class`
        : 'flex cursor-pointer items-center bottom-area-text res-class'
    "
    @click="changeArea(type)"
  >
    <div :class="`${type}-area-circle`"></div>
    <span :class="`active_${type}`">
      {{ name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      required: false,
    },
    name: {
      required: false,
    },
  },
  methods: {
    changeArea(type) {
      this.$emit('changeArea', type)
    },
  },
}
</script>

<style lang="scss">
.service-area-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #dff7e4;
  border: 1px solid #00b44d;
  margin-right: 5px;
}
.parking-area-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #dff7e4;
  border: 1px solid #00acff;
  margin-right: 5px;
}
.restricted-area-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #ffe4e4;
  border: 1px solid #ff0000;
  margin-right: 5px;
}
.slow_zone-area-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #ffd7bb;
  border: 1px solid #ff7c10;
  margin-right: 5px;
}
.rent-area-circle {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #d8b4fe;
  border: 1px solid #581c87;
  margin-right: 5px;
}
.bottom-area-text {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2f40;
}
.active_service {
  color: #00b44d !important;
}
.active_parking {
  color: #00acff !important;
}
.active_restricted {
  color: #ff0000 !important;
}
.active_slow_zone {
  color: #ff7c10 !important;
}
.active_rent {
  color: #581c87 !important;
}
@media (max-width: 990px) {
  .res-class {
    padding: 10px;
  }
}
</style>
