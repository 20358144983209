<template>
  <div>
    <transition name="fade">
      <button
        v-if="isOpen()"
        @click="close()"
        tabindex="-1"
        class="fixed inset-0 w-full h-full opacity-50 cursor-default bg-oBlack"
        style="z-index: 1050;"
      ></button>
    </transition>
    <transition
      name="SOR"
      enter-active-class="transition duration-300 ease-out transform"
      leave-active-class="transition duration-300 ease-in transform"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="isOpen()"
        class="fixed bottom-0 right-0 h-full  bg-white border-l-2 border-gray-400 border-opacity-50 shadow-lg container-sor"
        :class="getSize"
      >
        <header :class="{ 'mt-5 px-4 mb-6': this.$slots.header }">
          <slot name="header"></slot>
        </header>

        <div
          class="pb-4 overflow-x-hidden overflow-y-auto sb-farhan"
          :style="`max-height:${getDefaultSlotHeight}`"
        >
          <slot>Body slot (default)</slot>
        </div>

        <div v-if="this.$slots.footer" class="w-full">
          <footer class="w-full h-auto px-6 pt-2 pb-5">
            <slot name="footer">Footer Slot</slot>
          </footer>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// overflow-y-auto
export default {
  name: 'SlideOverRight',
  props: {
    id: {
      required: true,
      type: String,
    },
    fullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    editType: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // events
      openingEvent: `sor-open-${this.id}`,
      closingEvent: `sor-close-${this.id}`,
      togglingEvent: `sor-toggle-${this.id}`,
      // state
      show: false,
    }
  },
  computed: {
    getSize: function() {
      if (this.editType == 'scooter') {
        return this.fullWidth
          ? 'w-full px-4'
          : '2xl:w-96 sm:w-5/5 md:w-4/6 px-4'
      } else if (this.editType == 'promotion') {
        return this.fullWidth
          ? 'w-full px-4'
          : 'w-2/4 md-max:w-full lg-max:w-1/2 2xl:w-2/5 px-4'
      } else if (this.editType == 'pass') {
        return this.fullWidth
          ? 'w-full px-4'
          : 'w-2/4 md-max:w-full lg-max:w-1/2 2xl:w-2/5 px-4'
      } else if (this.editType == 'roles') {
        return this.fullWidth
          ? 'w-full px-4'
          : '2xl:w-2/4 sm:w-5/5 md:w-4/6 px-4'
      } else if (this.editType == 'marketplace') {
        return this.fullWidth
          ? 'w-full px-4'
          : 'w-2/4 md-max:w-full lg-max:w-1/2 2xl:w-2/5 px-4'
      } else if (this.editType == 'whats-new') {
        return this.fullWidth
          ? 'w-full px-4'
          : 'w-2/4 md-max:w-full lg-max:w-1/2 2xl:w-2/5 px-4'
      } else if (this.editType == 'bulk-update') {
        return this.fullWidth
          ? 'w-full'
          : 'w-3/4 md-max:w-full lg-max:w-1/2 2xl:w-2/5 px-0 my-0'
      } else if (this.editType == 'vehicle-inspection') {
        return this.fullWidth ? 'w-full px-4' : 'w-2/5 md-max:w-full  px-4'
      } else {
        return this.fullWidth
          ? 'w-full px-4'
          : 'w-2/4 2xl:w-2/6 xl:w-3/6 lg:w-3/6 md:w-3/6 sm:w-5/5 px-4'
      }
    },
    getDefaultSlotHeight: function() {
      // These values are based on header & footer height (h-12)
      // Measured by trail & error process
      // both slots are present
      // if (this.$slots.header && this.$slots.footer) {
      //   return "75%";
      // }
      // at least one slot is present
      // if (this.$slots.header || this.$slots.footer) {
      //   return "75%";
      // }
      // none of the slot is present, use full height
      return '100%'
    },
  },
  mounted: function() {
    this.handleEvents()
  },
  watch: {
    show: function(UpdatedState) {
      if (UpdatedState === true) {
        document.body.style.overflow = 'hidden'
        // Adding margin-right due to scrollbar flickering issue
        document.body.style.marginRight = '11px'
      } else {
        // wait for the animation to finish
        setTimeout(function() {
          document.body.style.overflow = 'auto' //made by
          document.body.style.marginRight = ''
        }, 350)
      }
    },
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      // console.log("close-listened");
      if (this.editType == 'roles') this.show = false
      else this.$emit('showModal')
      //this.show = false;
      document.body.style.overflow = 'auto'
    },
    toggle() {
      // this.handleOverflow();
      this.show = !this.show
    },
    isOpen() {
      return this.show === true
    },
    // Call on Mounted
    handleEvents() {
      window.addEventListener(this.openingEvent, () => {
        this.open()
        //console.log(this.openingEvent + ' listened')
      })
      window.addEventListener(this.closingEvent, () => {
        this.close()
        //console.log(this.closingEvent + ' listened')
      })
      window.addEventListener(this.togglingEvent, () => {
        this.toggle()
        //console.log(this.togglingEvent + ' listened')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container-sor {
  display: grid;
  grid-template-rows: auto 1fr auto;
  z-index: 1100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
