var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('add-edit-slow-zone-area'),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10"},[_c('div',{},[_c('title-plus',{attrs:{"title":_vm.$t('components.slowSpeedAreaManagement.headline')},on:{"plus":_vm.add}})],1)]),_c('div',{staticClass:"flex flex-col mt-4 mb-10 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.slowSpeedAreaManagement.summary.totalSlowSpeedAreas'
            ),"value":_vm.indexMetaData.summary.total,"variant":"gray"}})],1)]),(_vm.$acl.canNotView('Slow Speed Areas'))?_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")]):_c('FSTable',{attrs:{"fst-id":"SlowSpeedAreasIndex","headers":_vm.tableHeaders,"endpoint":_vm.indexDataEndpoint,"isMultipleViewEnabled":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); },"view":function (v) { return (_vm.layoutView = v); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return [(_vm.layoutView === 'list')?_vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                  name: 'ViewSlowZoneArea',
                  params: { id: item.id },
                }}},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])],1),_c('FSTableRowItem',[_c('router-link',{staticClass:"font-semibold text-blue-700 capitalize",attrs:{"to":{
                  name: 'ViewSlowZoneArea',
                  params: { id: item.id },
                }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('FSTableRowItem',{attrs:{"text":item.geofence ? item.geofence.name : '--'}}),_c('FSTableRowItem',{attrs:{"text":item.address ? item.address : '--'}}),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_active ? 'green' : 'gray',"text":item.is_active ? 'Active' : 'Inactive',"profile":"fleet"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"slowSpeedArea","data":item},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)])],1)}):_vm._e(),(_vm.layoutView === 'grid')?_vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableGridItem',[_c('div',{staticClass:"bg-gray-50 rounded-lg flex justify-between items-center p-1"},[_c('div',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewSlowZoneArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"slowSpeedArea","data":item},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)]),_c('div',{staticClass:"h-52"},[_c('MapView',{attrs:{"data":item}})],1)])],1)}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }